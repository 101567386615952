<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">확인</v-btn>
      <!-- <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn> -->

      <v-toolbar-title
        slot="title-area"
        class="white--text">공유요청</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container grid-list-xs class="pb-0 mb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <!-- <v-divider class="white--text"></v-divider> -->
              <v-card class="elevation-0 mt-0 mb-3 blue-grey--text">
                <v-card-text>
                  <p>회원님은 현재 공유 요청중입니다.</p>
                  <p>관리(대행)자가 공유 설정을 완료하면 이메일이 발송될 것입니다.</p>
                  <p>메일을 확인하시고 다시 로그인해 주시기 바랍니다</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justify="center"
              >
                <v-btn
                  color="primary"
                  @click.stop="requestWorkGroupShare"
                >
                  <v-icon small class="mr-2">mdi-email-sync-outline</v-icon>
                  다시 요청하기
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal'

export default {
  components: {
    baseModal
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 650,
      zIndex: 300
    }
  }),

  mounted () {
    if (this.$store.state.ui) {
      // 이미 로그인된 상태라 저장소에 유저정보가 저장된 경우
      this.dialog = true
    } else {
      // 아니면 - 백앤드에서 토큰을 담아가지고 왔다. 이걸로 로그인 시킨다!
      if (this.$route.query && this.$route.query.token) {
        this.loginWithToken(this.$route.query.token)
        this.dialog = true
      } else {
        // 이도저도 아니면 뭔가 잘못되었으니 초기화면으로
        this.redirect('/')
      }
    }
  },

  methods: {
    dummy () {
    },
    sbpop (msg) {
      this.$store.commit('SB_POP', { msg })
    },
    // 구분: 토큰을 백엔드에서 넘겨받아 로그인 처리하는 함수
    async loginWithToken (token) {
      try {
        if (token) {
          // 로그인 : 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })
        } else {
          throw new Error('Token Not Reponsed : [401]')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    async agree () {
      try {
        this.redirect('/')
      } catch (e) {
        this.sbpop(e)
      }
    },
    cancel () {
      try {
        this.redirect('/')
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.dialog = false
      this.$router.push(to)
    },
    // 구분: 조직공유 다시 요청 버튼을 클릭시
    requestWorkGroupShare () {
      try {
        this.redirect('/auth/requestLawWorkShare') // 조직공유 페이지로 이동
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
